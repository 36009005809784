import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import "../style/index.scss";
import Profile from "../components/Profile";
import Listing from "../components/Listing";
import Card from "../components/Card";

export default ({ data }) => {
  return (
    <Layout>
      <div className="grid gap-4 lg:grid-cols-3">
        <Profile className="row-span-2 col-span-2 lg:col-span-1" />
        <Card className="col-span-2" title={<h2 className="font-bold m-0">Latest.</h2>}>
          <Listing items={data.posts.nodes} />
        </Card>
        <Card className="col-span-2" title={<h2 className="font-bold m-0">Projects.</h2>}>
          <Listing items={data.projects.nodes} isThumb />
        </Card>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProjectsAndPosts {
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "\\\\/projects/" }, frontmatter: { published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          date
          title
          featuredImage {
            childImageSharp {
              fixed(width: 100, height: 100, fit: COVER) {
                src
              }
            }
          }
        }
        slug
        id
      }
    }
    posts: allMdx(
      filter: { fileAbsolutePath: { regex: "\\\\/posts/" }, frontmatter: { published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          date
          title
          featuredImage {
            childImageSharp {
              fixed(width: 100, height: 100, fit: COVER) {
                src
              }
            }
          }
        }
        slug
        id
      }
    }
  }
`;
