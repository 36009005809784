import React from "react";
import kevImage from "../assets/images/kev-xl.webp";
import Card from "./Card";
import cameraImage from "../assets/images/camera.png";
import earthImage from "../assets/images/earth.png";
import laptopImage from "../assets/images/laptop.png";
import shirtImage from "../assets/images/shirt.png";

const Profile = props => {
  return (
    <Card {...props}>
      <div className="sm:flex lg:block">
        <div
          className="rounded-md bg-cover bg-bottom w-full h-72 sm:w-60 sm:h-auto lg:h-72 xl:h-80 lg:w-full"
          role="img"
          alt="Kevin Borrill wearing a jazzy shirt"
          style={{ backgroundImage: `url(${kevImage})` }}
        ></div>
        <div className="flex-1 pl-0 pt-4 sm:pl-4 lg:pl-0 sm:pt-0">
          <h2 className="text-5xl md:text-6xl lg:mt-4">
            Oh hey!{" "}
            <span role="img" aria-label="Waving hand emoji" className="hover:animate-wiggle inline-block">
              👋
            </span>
          </h2>
          <div className="mt-4 bg-gray-100 dark:bg-gray-800 p-2 rounded-md text-sm md:text-base">
            <div className="flex mb-1">
              <img alt="" role="presentation" src={earthImage} className="h-6 md:h-8 mr-2" />
              <p>
                Web Team Lead, coding the world a better place at{" "}
                <a
                  href="https://www.3sidedcube.com"
                  target="_blank"
                  rel="noopenner"
                  className="text-purple-500 dark:text-purple-400"
                >
                  3 SIDED CUBE
                </a>
              </p>
            </div>
            <div className="flex mb-1">
              <img alt="" role="presentation" src={laptopImage} className="inline h-6 md:h-8 mr-2" />
              <p>Web, JavaScript, Alexa, Node, AWS</p>
            </div>
            <div className="flex items-center mb-1">
              <img alt="" role="presentation" src={cameraImage} className="inline h-6 md:h-8 mr-2 	" />
              <a
                href="https://unsplash.com/@kev2480"
                target="_blank"
                rel="noopenner noreferrer"
                className="text-purple-500 dark:text-purple-400"
              >
                Photographer
              </a>
            </div>
            <div className="flex items-center">
              <img alt="" role="presentation" src={shirtImage} className="inline h-6 md:h-8 mr-2" />
              <p>Jazzy Shirts</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
