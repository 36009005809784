import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

const Listing = ({ items = [], isThumb = false }) => {
  const listItemClasses = classnames(
    "c-listing__item transition",
    isThumb
      ? "block w-20 h-20 ml-0 m-2 mr-4 bg-no-repeat bg-center bg-cover rounded-md shadow hover:shadow-md focus-within:shadow-md transform-gpu c-listing__item--thumb"
      : "mb-5"
  );
  const listItemInnerClasses = classnames("flex items-center", isThumb && "sr-only");
  const linkClasses = classnames("block", isThumb && "w-full h-full");

  return (
    <section>
      <ul className={classnames("c-listing", isThumb && "flex flex-wrap")}>
        {items.map(({ id, excerpt, frontmatter, slug }) => {
          const date = new Date(frontmatter.date).toLocaleDateString();
          return (
            <li
              key={id}
              className={listItemClasses}
              style={{
                backgroundImage: frontmatter.featuredImage
                  ? `url(${frontmatter.featuredImage.childImageSharp.fixed.src})`
                  : ""
              }}
              data-title={frontmatter.title}
            >
              <Link to={`/${slug}`} className={linkClasses}>
                <div className={listItemInnerClasses}>
                  <h3 className="font-extrabold hover:underline truncate">{frontmatter.title}</h3>
                  <p className="text-purple-500 dark:text-purple-400 text-sm ml-2">{date}</p>
                </div>
                <p className="mb-0">{excerpt}</p>
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Listing;
